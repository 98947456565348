import { apiUrl, tokenName, apiMaxTime, storageKey} from '../config.json'
import {handleResponse, requestTimeout} from '../Helpers'

export const authApi = {
    login,
    signup,
    social_auth,
    logout,
    authCheck,
    add_address,
    verifyPhone
};


async function logout(){
    localStorage.removeItem(tokenName);    
    localStorage.removeItem(storageKey);
    return true;

}



async function login(form_data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'app-login', requestOptions))
                .then(handleResponse)
                .then(async (user) => {
                    if (user.token) {                
                        localStorage.setItem(tokenName, user.token)
                    }
                    return user;
                });
}

async function signup(form_data) {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'app-signup', requestOptions))
                .then(handleResponse)
                .then(async (user) => {
                    if (user.token) {                
                        localStorage.setItem(tokenName, user.token)
                    }
                    return user;
                });
}

async function social_auth(form_data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'social-auth', requestOptions))
                .then(handleResponse)
                .then(async (user) => {
                    if (user.token) {                
                        localStorage.setItem(tokenName, user.token)
                    }
                    return user;
                });
}




async function authCheck() {
    const app_token = localStorage.getItem(tokenName)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        },
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'auth-check', requestOptions))
                .then(handleResponse)
                .then(async (user) => {
                    if (user.token) {                
                        localStorage.setItem(tokenName, user.token)
                    }
                    return user;
                });
}

async function add_address(form_data) {
    const app_token = localStorage.getItem(tokenName)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'add-address', requestOptions))
                .then(handleResponse)
                .then((data) => {  
                    return data;
                });
}

async function verifyPhone(phone) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({phone})
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'verify', requestOptions))
        .then(handleResponse)
        .then(result => {    
            return result;
        });
}