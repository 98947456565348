import React from 'react'
import {Box, Text, HStack, Button} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { Wrapper } from '.'

export const Footer = (props) => {
    return(
        <Box py="2em" bg="brand.900" color="whiteAlpha.600">  
            <Wrapper>
                <HStack  justifyContent="space-between" alignItems="center">                    
                    <Box w={{ md: '75%', sm: '0%' }} d={{ sm: 'none', md: 'flex' }}>
                        <Button size="sm" as={Link} to="/about" mx="2"  colorScheme="whiteAlpha" variant="ghost">About Us</Button>
                        <Button size="sm" as={Link} to="/how-it-works" colorScheme="whiteAlpha"  mx="2" variant="ghost">How it works</Button>
                        <Button size="sm" as={Link} to="/order" mx="2" colorScheme="whiteAlpha"  variant="ghost">Order Online</Button>
                        <Button size="sm" as={Link} to="/contact" mx="2" colorScheme="whiteAlpha"  variant="ghost">Contact</Button>
                        <Button size="sm" as={Link} to="/contact" mx="2" colorScheme="whiteAlpha"  variant="ghost">Privacy Policy</Button>
                        <Button size="sm" as={Link} to="/contact" mx="2" colorScheme="whiteAlpha"  variant="ghost">Terms & Conditions</Button>
                    </Box>
                    <Box w={{ md: '25%', sm: '50%' }} d="flex" justifyContent="flex-end">
                        <Text fontSize="sm">&copy; 2021. Laundrix.pk. All rights reserved</Text>
                    </Box>
                </HStack>
            </Wrapper>
        </Box>
    )
}

