import React from 'react'
import {Box, Image, HStack, Button} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { Wrapper } from '.'
import { isEmpty } from '../Helpers'
import { useAuth } from '../Hooks'

export const Header = (props) => {

    const {user} = useAuth()

    console.log("User", user)
    return(
        <Box py="20px" bg="white"> 
            <Wrapper>
                <HStack  justifyContent="space-between" alignItems="center">
                    <Box w={{ md: '25%', sm: '50%' }}>
                        <Box w="180px">
                            <Link to="/">
                                <Image src="/assets/laundrix-logo-h.svg" w="100%" h="auto" loading="lazy" alt="Laundirx" title="Laundrix" />
                            </Link>
                        </Box>
                    </Box>
                    <Box w={{ md: '50%', sm: '0%' }} d={{ sm: 'none', md: 'flex' }} justifyContent="center">
                        <Button as={Link} to="/about" mx="2" variant="ghost">About Us</Button>
                        <Button as={Link} to="/how-it-works" mx="2" variant="ghost">How it works</Button>
                        <Button as={Link} to="/order" mx="2" variant="ghost">Order Online</Button>
                        <Button as={Link} to="/contact" mx="2" variant="ghost">Contact</Button>
                    </Box>
                    <Box w={{ md: '25%', sm: '50%' }} d="flex" justifyContent="flex-end">
                        {
                            (user || !isEmpty(user)) ?
                                <Button as={Link} to="/account" colorScheme="twitter" size="sm">My Account</Button>
                            :
                            <>
                                <Button as={Link} to="/auth" variant="ghost" colorScheme="twitter" size="sm" mr="2">Create an Account</Button>
                                <Button as={Link} to="/auth" colorScheme="twitter" size="sm">Login</Button>
                            </>
                        }
                        
                    </Box>
                </HStack>
            </Wrapper>
        </Box>
    )
}

